@media only screen and (max-width: 2560px) {
  .topbar {
    max-width: 1330px;
  }
  .section-container {
    max-width: 1160px;
  }
  .text-container {
    max-width: 880px;
  }
  .demonstration_section {
    &-container {
      &.section-container {
        max-width: 1160px - 200px;
      }
    }
  }
}

@media only screen and (max-width: 1699px) {
  .get_offer {
    &-container {
      padding: 38px 0;
    }
  }
}

@media only screen and (max-width: 1439px) {
  .get_offer {
    &-container {
      padding: 32px 0;

      &__title {
        padding-bottom: 26px;
      }
    }

    &-image {
      width: 40%;
      background-size: contain;
    }
  }

  .main {
    .topbar {
      &-item:not(:first-child) {
        margin-left: 10px;
      }
    }
  }
}

@media only screen and (max-width: 1279px) {
  .topbar {
    max-width: 1280px;
  }
  .section-container {
    max-width: 1080px;
  }

  .features_section {
    &-container {
      gap: 20px;

      .feature {
        flex: 1;
      }
    }
  }

  .possibilities_cards {
    flex-wrap: nowrap;
    flex-direction: column;
    justify-content: space-between;
  }

  .community_card {
    &-img {
      position: relative;

      img {
        position: absolute;
      }
    }
  }

  .review_section {
    .review-swiper {
      max-width: 900px;
    }
  }

  // CASES

  .welcome_section {
    &-info {
      flex-basis: 600px;
    }

    &-img {
      margin-left: -50px;

      iframe {
        width: 400px;
        height: 225px;
      }
    }
  }

  .cases.welcome_section {
    & .welcome_section-img {
      margin-left: 0;
    }
  }

  .results-section {
    .img-block {
      img {
        width: 30vw;
      }
    }
  }

  .get_offer {
    &-image {
      width: 32%;
      background-size: cover;

      &.image--left {
        background-position: right center;
      }

      &.image--right {
        background-position: left center;
      }
    }
  }

  .possibility_card {
    max-width: none;
    margin-bottom: 10px;
  }

  .possibilities_section {
    &-container {
      .posibilities_image {
        .mockups-container {
          width: 85%;
          height: 70%;

          .posibilities-screen--laptop {
            width: 500px;
            height: 550px;
            right: auto;
          }
        }
      }
    }
  }

  .branding_section {
    .branding {
      &-card {
        .branding-img {
          .branding-mock-container {
            .branding-screen {
              &--laptop {
                width: 460px;
                right: -60px;
              }
            }
          }
        }
      }
    }
  }

  .topbar {
    &-item {
      &:first-child {
        margin-right: 0;
      }

      & + & {
        margin-left: 22px;
      }
    }
  }

  .button {
    &-switch-lang {
      + ul {
        transform: none;
        left: auto;
        right: 0;
      }
    }
  }

  .card {
    &--actionable {
      &:hover,
      &.active {
        &::after {
          @include card_after_scale(45);
        }
      }
    }
  }
}

@media only screen and (max-width: 1160px) {
  .topbar {
    &-item {
      font-size: 14px;

      & + & {
        display: none;
      }
    }
  }
}

@media only screen and (max-width: 1024px) {
  .topbar,
  .section-container {
    max-width: 904px;
  }

  h1 {
    font-size: 42px;
  }
  h2 {
    font-size: 24px;
  }
  h3 {
    font-size: 20px;
  }

  .features_section {
    &-container {
      gap: 20px;

      .feature {
        flex: 1;
      }
    }
  }

  .possibilities_section {
    &-container {
      .posibilities_image {
        .mockups-container {
          .posibilities-screen--laptop {
            width: 400px;
            height: 440px;
          }
        }
      }
    }
  }

  .possibilities_cards {
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 20px;
  }

  .possibility_card {
    padding: 20px;
    max-width: none;
    margin-bottom: 10px;

    img,
    svg {
      margin-bottom: 16px;
    }

    p {
      text-align: center;
    }
  }

  .welcome_section {
    &-title {
      font-size: 42px;
    }

    &:not(.cases) {
      overflow: hidden;
    }

    &-container {
      padding-top: 160px;
    }

    &-img {
      margin-left: -100px;

      iframe {
        width: 300px;
        height: 169px;
      }
    }
  }

  .community_card {
    .card-info {
      padding: 30px;
    }
  }

  .team_section {
    &-container {
      .skills-list {
        flex-wrap: wrap;

        .item {
          flex: 1 1 300px;

          &:first-child {
            margin-bottom: 24px;
          }

          &:nth-child(3) {
            .info {
              border-left: none;
            }
          }

          img {
            width: 100%;
          }
        }
      }
    }
  }

  .book_section {
    &-container {
      height: 600px;
    }

    &-form {
      padding-right: 80px;
    }
  }

  .review_section {
    .swiper-button-prev,
    .swiper-button-next {
      display: none;
    }
  }

  // CASES PAGE
  .text-container {
    max-width: 840px;
  }
  .other-cases-section {
    padding: 40px 0;

    &-container {
      width: 840px;
    }
  }

  .welcome_section {
    .cases &-title {
      font-size: 32px;
    }
  }

  .branding_section {
    .branding {
      &-card {
        height: 360px;

        .branding-img {
          .branding-mock-container {
            height: 460px;

            .branding-screen {
              &--laptop {
                width: 400px;
                right: -50px;
                object-fit: contain;
              }

              &--phones {
                width: 400px;
                right: -50px;
                object-fit: contain;
              }
            }
          }
        }
      }
    }
  }

  .cases.welcome_section {
    & .welcome_section-img {
      picture {
        width: 100%;
      }
    }
  }

  .modal-wrapper {
    &.privacy-modal {
      .modal-container {
        &__privacy_title {
          font-size: 24px;
        }
      }
    }
  }

  .demonstration_section {
    &__title {
      font-size: 42px;
    }
  }

  .phone_card {
    .phone,
    .email {
      font-size: 42px;
    }
  }

  .demonstration_form {
    .final-screen {
      &__title {
        font-size: 24px;
      }
    }
  }
}

@media only screen and (max-width: 932px) {
  .topbar,
  .section-container {
    max-width: 812px;
  }

  .text-container {
    max-width: 800px;
  }

  .welcome_section {
    &-app {
      flex-wrap: wrap;
    }

    &-info {
      flex-shrink: inherit;
      width: 100%;
    }

    &-img {
      margin-left: -50px;

      iframe {
        width: 400px;
        height: 225px;
      }
    }
  }

  .other-cases-section {
    &-container {
      width: 800px;
    }
  }

  .community_card {
    &-img {
      img {
        position: absolute;
      }
    }
  }

  .work_card {
    padding: 30px;
  }

  .security_card {
    padding: 30px;
  }

  .features_section {
    &-container {
      flex-wrap: wrap;
      justify-content: space-between;
      gap: 40px;

      .feature {
        flex: 1 1 260px;
        border-radius: 24px;
        max-width: none;

        h3 {
          min-height: auto;
        }

        &-description {
          min-height: auto;
        }
      }
    }
  }

  .swiper-slide {
    .review-card {
      height: 600px;
    }
  }

  // CASES
  .results-section {
    .img-block {
      img {
        width: 30vw;
        height: 60vw;
      }
    }
  }

  .branding_section {
    .branding {
      &-card {
        .branding-content {
          .branding-buttons {
            margin-top: 28px;
          }
        }

        .branding-img {
          .branding-mock-container {
            height: 400px;

            .branding-screen {
              &--laptop {
                width: 400px;
                right: -44px;
              }

              &--phones {
                width: 400px;
                right: -44px;
              }
            }
          }
        }
      }
    }
  }

  .solution_section {
    &-container {
      .solution-list {
        &--item {
          &__title {
            min-height: 104px;
          }
        }
      }
    }
  }

  .main {
    .topbar {
      &-item:not(:first-child) {
        margin-left: 6px;
      }
    }
  }
}

@media only screen and (max-width: 853px) {
  .topbar,
  .section-container {
    max-width: 722px;
  }

  .text-container {
    max-width: 740px;
  }

  .other-cases-section {
    &-container {
      width: 740px;
    }
  }

  .welcome_section {
    &-container {
      flex-direction: column;
    }

    &-links {
      margin-right: 0;
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;

      a:first-child {
        margin-bottom: 16px;
      }
    }

    &-img {
      margin-left: 0;
      order: -1;
      width: 100%;
      max-width: 520px;

      img.header-mock {
        width: 100%;
        aspect-ratio: 520 / 660;
      }
    }
  }

  .community_card {
    .card-info {
      &__title {
        text-align: center;
      }

      p {
        text-align: center;
      }
    }
  }

  .information_cards {
    flex-wrap: wrap;

    .information-card {
      padding: 20px;
      max-width: 100%;
      flex: 1 1 220px;

      img {
        margin-bottom: 16px;
      }

      .card-info {
        flex-direction: column;
        width: 100%;
      }
    }
  }

  .swiper-slide {
    .review-card {
      .review-img {
        min-width: 300px;
      }

      .review-info {
        .content {
          font-size: 18px;
        }
      }
    }
  }

  .possibilities_section {
    &-container {
      .posibilities_image {
        .mockups-container {
          width: 85%;
          height: 50%;
        }
      }
    }
  }

  .branding_section {
    .branding {
      &-card {
        .branding-img {
          .branding-mock-container {
            height: 340px;

            .branding-screen {
              &--laptop {
                width: 300px;
                right: -39px;
              }

              &--phones {
                width: 300px;
                right: -39px;
              }
            }
          }
        }
      }
    }
  }

  .cases {
    .welcome_section {
      &-info {
        flex-basis: auto;
      }

      &-container {
        justify-content: flex-start;
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .topbar,
  .section-container {
    max-width: 708px;
  }

  .text-container {
    max-width: 640px;
  }

  .welcome_section {
    &-img {
      .header-mock {
      }
    }
  }

  .other-cases-section {
    &-container {
      width: 640px;
    }

    .swiper-slide {
      .other-cases-card {
        flex-direction: column-reverse;

        .other-cases-info {
          min-height: 350px;
        }

        .other-cases-img {
          height: 350px;
        }
      }
    }
  }

  .book_section {
    &-container {
      flex-direction: column;
      height: auto;
    }

    &-img {
      width: 100%;
      height: 600px;
    }

    &-form {
      max-width: 100%;
      padding: 60px;
      text-align: center;
    }
  }

  .community_card {
    &-img {
      img {
        position: absolute;
      }
    }
  }

  .modal-wrapper {
    &.privacy-modal {
      .modal-window {
        max-width: none;
        width: 100%;
        border-radius: 0;
      }

      .modal-container {
        padding: 20px 20px;
      }
    }

    .modal-container {
      max-width: 100%;
      width: 100%;
    }
  }

  .cookie-modal {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    left: 0;
    right: 0;
    bottom: 0;
    max-width: none;

    &__title {
      @include respond-to(phone-large) {
        font-size: 16px;
      }
    }

    p {
      font-size: 13px;
    }
  }

  .blog-card {
    width: 100%;
  }

  .blog_list {
    flex-direction: column;

    .blog-card {
      flex-direction: row;
      margin-right: 0;
      margin-bottom: 20px;

      .blog-img {
        flex: 2;
        border-top-right-radius: 0;
        background-size: cover;
      }

      .blog-info {
        flex: 1;
      }
    }
  }

  .swiper-slide {
    .review-card {
      .review-info {
        .header {
          padding-left: 28px;
        }

        .content {
          padding: 0 32px 40px 32px;
        }
      }
    }
  }

  // CASES
  .cases.welcome_section {
    margin-bottom: 200px;

    .welcome_section-img {
      height: 150px;
    }
  }
  .solution_section {
    &-container {
      .solution-list {
        &--item {
          &__title {
            min-height: 130px;
          }
        }
      }
    }
  }
  .review-section-cases {
    &_person {
      min-width: 270px;
    }
  }

  .possibilities_section {
    &-container {
      flex-wrap: wrap;

      .posibilities_image {
        flex: 0 0 100%;
        order: -1;

        .mockups-container {
          width: 35%;
          height: 350px;
          margin-bottom: 20px;

          .posibilities-screen--laptop {
            width: 120%;
            height: auto;
            bottom: 0;
            left: 0;
            transform: translateX(-10%);
          }
        }
      }

      .possibilities_cards {
        flex: 0 0 100%;
      }
    }
  }

  .card {
    &--actionable {
      &:hover,
      &.active {
        &::after {
          @include card_after_scale(50);
        }
      }
    }
  }

  .get_offer {
    &.cases & {
      &-container {
        h4,
        &__title {
          width: 100%;
        }
      }
    }
  }

  .demonstration_form {
    .fields {
      .fields_contacts {
        .input:first-child {
          margin-right: 10px;
        }
      }
    }
  }
}

@media only screen and (max-width: 667px) {
  header {
    .topbar .topbar-item .logo {
      width: 90px;
    }
  }
  .button {
    font-size: 14px;
  }
  .topbar,
  .section-container {
    max-width: 540px;
  }

  .text-container {
    max-width: 90%;
    padding: 40px;
  }

  .other-cases-section {
    &__title {
      padding-left: 40px;
    }

    &-container {
      max-width: 90%;
    }

    .swiper-slide {
      .other-cases-card {
        .other-cases-info {
          padding: 40px;
        }
      }
    }
  }

  h1 {
    font-size: 28px;
    line-height: 1.08;
  }

  h2 {
    font-size: 24px;
    line-height: 1.12;
  }

  h3 {
    font-size: 20px;
    line-height: 1.11;
  }

  p {
    font-size: 18px;
  }

  .security_section {
    @include section_default_paddings_mobile();
  }

  .welcome_section {
    height: auto;

    & .normal {
      &--downloads {
        margin-bottom: 16px;
      }
    }

    &-app {
      &-downloads {
        flex-shrink: inherit;
        flex-wrap: wrap;

        div {
          margin-bottom: 16px;
        }
      }
    }

    &-title {
      font-size: 28px;
    }

    &:not(.cases) {
      .welcome_section-description {
        font-size: inherit;
      }
    }

    &-links {
      a:first-child {
        margin-bottom: 0;
      }
    }

    &-info {
      padding-top: 0;
      flex-basis: auto;
    }

    &-img {
    }
  }

  .solution_section {
    @include section_default_paddings_mobile();

    &-container {
      .slogan {
        margin-bottom: 40px;
      }

      .solution-list {
        flex-direction: column;

        &--item {
          flex: 0 0 100%;
          margin-bottom: 24px;

          &__title {
            min-height: auto;
          }

          p {
            margin-bottom: 0;
          }
        }
      }
    }
  }
  .information_section {
    @include section_default_paddings_mobile();
  }

  .features_section {
    @include section_default_paddings_mobile();

    &-container {
      gap: 20px;

      .feature {
        flex: 1 1 220px;
        max-width: none;

        &-title {
          line-height: 1.11;
        }
      }
    }
  }

  .work_section {
    @include section_default_paddings_mobile();
  }

  .community_section {
    @include section_default_paddings_mobile();
  }

  .brand_section {
    @include section_default_paddings_mobile();
    padding-bottom: 20px;
  }

  .community_card {
    flex-direction: column-reverse;

    &-img {
      border-bottom-right-radius: 0;
      border-top-left-radius: 16px;
      border-top-right-radius: 16px;

      img {
        position: inherit;
      }
    }
  }

  .team_section {
    @include section_default_paddings_mobile();

    &-container {
      .skills-list {
        .item {
          margin-bottom: 24px;

          &:last-child {
            margin-bottom: 0;
          }

          &:nth-child(2) {
            .info {
              border-left: none;
            }
          }

          &:nth-child(3) {
            .info {
              border-right: none;
            }
          }
        }
      }
    }
  }

  .book_section {
    @include section_default_paddings_mobile();
    padding-bottom: 0;
  }

  .blog_list {
    .blog-card {
      flex-direction: column;
    }
  }

  .review_section {
    @include section_default_paddings_mobile();

    .swiper-wrapper {
      padding-bottom: 0;
    }
  }

  .swiper-slide {
    .review-card {
      flex-direction: column;

      .review-img {
        min-width: none;
        height: 200px;
        flex-shrink: 0;
        background-position: top;
        border-bottom-left-radius: 0;
        border-top-right-radius: 16px;
        justify-content: center;
        background: linear-gradient(
            91.49deg,
            rgba(0, 0, 0, 0) 22%,
            rgba(149, 27, 204, 0.2) 35%,
            rgba(71, 0, 187, 0.7) 89%
          ),
          linear-gradient(
            161.49deg,
            rgba(149, 27, 204, 0) 38.43%,
            rgba(149, 27, 204, 0.3) 58.43%,
            rgba(71, 0, 187, 0.7) 80%
          ),
          linear-gradient(107.28deg, rgba(149, 27, 204, 0) 20.69%, rgba(149, 27, 204, 0.8) 95%);

        img {
          object-position: top;
          width: 40%;
        }

        .review-person {
          width: 60%;
          margin-left: auto;
          margin-right: -10px;
          text-align: left;

          .review-person__title {
            font-size: 22px;
          }

          p {
            font-size: 16px;
          }
        }
      }

      .review-info {
        flex-grow: 1;

        .header {
          height: 100px;
        }

        .content {
          &::before {
            content: '';
            margin-bottom: 0;

            .webp & {
              content: '';
            }
          }
        }
      }
    }
  }

  .footer {
    .footer-links {
      margin-bottom: 22px;

      .footer-item {
        padding-bottom: 10px;

        a {
          font-weight: 600;
          font-size: 18px;
        }

        span {
          display: inline-block;
          width: 100%;
          text-align: center;
          padding-right: 0;
        }
      }
    }
  }

  // CASES
  .cases.welcome_section {
    margin-bottom: 160px;

    .welcome_section-img {
      height: 20vw;
    }
  }

  .description-section {
    margin-top: 0;
  }

  .review-section-cases {
    .text-container {
      flex-direction: column;
      height: auto;
    }

    &_person {
      width: 100%;
      min-height: 300px;

      .position {
        width: 300px;
      }
    }

    &_description {
      padding: 50px;
      min-height: 300px;
      justify-content: center;
    }
  }

  .results-section {
    .img-block {
      flex-direction: column;

      img {
        width: auto;
        height: auto;
      }
    }
  }

  .branding_section {
    padding-bottom: 40px;

    .branding {
      &-card {
        height: auto;
        padding: 30px;
        flex-wrap: wrap-reverse;
        text-align: center;

        .branding-content {
          flex: 1 1 100%;

          .branding-buttons {
            justify-content: center;
          }
        }

        .branding-img {
          margin-bottom: 20px;

          .branding-mock-container {
            width: 60%;
            height: 350px;
            transform: translateY(-30%);
            margin-bottom: -20%;

            .branding-screen {
              &--laptop {
                width: 140%;
                right: 0;
                left: 0;
                transform: translate(-14%, 16%);
              }

              &--phones {
                right: 0;
                left: 0;
              }
            }
          }
        }
      }
    }
  }

  .get_offer {
    @include section_default_paddings_mobile();

    &-image {
      width: 30%;
    }
  }

  .possibilities_section {
    @include section_default_paddings_mobile();

    &-container {
      .posibilities_image {
        .mockups-container {
          width: 40%;
          height: 250px;
        }
      }
    }
  }

  .form-modal {
    .demonstration_section__title {
      font-size: 28px;
    }
  }

  .modal-wrapper {
    &.privacy-modal {
      .modal-container {
        &__privacy_title {
          line-height: 1.12;
        }
      }
    }
  }

  .demonstration_section {
    &__title {
      font-size: 28px;
    }
  }

  .phone_card {
    .phone,
    .email {
      font-size: 28px;
      line-height: 1.08;
    }
  }
  .demonstration_form {
    .final-screen {
      &__title {
        line-height: 1.12;
      }
    }
  }
}

@media only screen and (max-width: 480px) {
  .topbar,
  .section-container {
    max-width: 420px;
  }

  .text-container {
    padding: 32px 20px;
  }

  .welcome_section {
    &-links {
      a:first-child {
        margin-bottom: 16px;
      }
    }
  }

  .security_card {
    flex-direction: column-reverse;
    justify-content: center;
    align-content: center;

    .card-info {
      margin-right: 0;

      &__title,
      p {
        text-align: center;
      }
    }

    img {
      align-self: center;
      margin-bottom: 24px;
    }
  }

  .community_card {
    .card-info {
      h2,
      p,
      &__title {
        text-align: center;
      }
    }
  }

  .work_card {
    flex-direction: column-reverse;
    justify-content: center;
    align-content: center;

    &-img {
      align-self: center;
      margin-bottom: 24px;
    }

    .card-info {
      margin-right: 0;

      h2,
      p,
      &__title {
        text-align: center;
      }
    }
  }

  .phone_card {
    .phone,
    .email {
      font-size: 24px;
    }
  }
  .modal-wrapper {
    &.privacy-modal {
      .modal-header {
        padding-top: 36px;
      }

      .modal-container {
        padding: 16px;
      }
    }
  }
  .book_section {
    &-img {
      height: 400px;
    }
  }

  .swiper-slide {
    .review-card {
      .review-img {
        height: auto;
        max-height: 240px;
        background: linear-gradient(
            91.49deg,
            rgba(100, 0, 200, 0.05) 28%,
            rgba(149, 27, 204, 0.3) 32%,
            rgba(71, 0, 187, 0.8) 89%
          ),
          linear-gradient(
            161.49deg,
            rgba(149, 27, 204, 0) 48.43%,
            rgba(149, 27, 204, 0.4) 58.43%,
            rgba(71, 0, 187, 0.7) 80%
          ),
          linear-gradient(
            107.28deg,
            rgba(149, 27, 204, 0) 10.69%,
            rgba(149, 27, 204, 0.3) 25.69%,
            rgba(149, 27, 204, 0.8) 95%
          );

        img {
          width: 120px;
        }

        .review-person {
          width: 65%;
          width: calc(100% - 100px);

          .review-person__title {
            font-size: 20px;
          }

          p {
            font-size: 14px;
          }
        }
      }

      .review-info {
        .header {
          height: 60px;

          .review-title {
            font-size: 14px;
          }

          img {
            width: 120px;
            display: none;
          }
        }

        .content {
          font-size: 16px;

          &::before {
            content: '';
            margin-bottom: 0;
          }
        }
      }
    }
  }

  // CASES
  .cases.welcome_section {
    margin-bottom: 140px;

    .welcome_section-img {
      height: 15vw;
    }
  }

  .other-cases-section {
    h2 {
      padding-left: 20px;
    }

    .swiper-slide {
      .other-cases-card {
        .other-cases-img {
          height: 280px;
        }

        .other-cases-info {
          padding: 20px;
        }
      }
    }
  }

  .possibilities_section {
    &-container {
      .posibilities_image {
        .mockups-container {
          width: 60%;
          height: 300px;
        }
      }
    }
  }

  .branding_section {
    .branding {
      &-card {
        .branding-img {
          .branding-mock-container {
            width: 75%;
            height: 300px;
            transform: translateY(-30%);
            margin-bottom: -30%;

            .branding-screen {
              &--phones {
                width: 100%;
              }
            }
          }
        }
      }
    }
  }

  .card {
    &--actionable {
      &:hover,
      &.active {
        &::after {
          @include card_after_scale(37);
        }
      }
    }
  }

  .video-modal {
    iframe,
    .modal-close-overlay {
      width: 64vw;
      height: 36vw;
    }
  }
}

@media only screen and (max-width: 425px) {
  .topbar,
  .section-container {
    max-width: 385px;
    min-width: 320px;
  }

  header {
    .topbar .topbar-item .logo {
      width: 86px;
    }
  }
  .topbar {
    .button--orange {
      margin-left: 10px;
      margin-right: 8px;
    }
  }

  .features_section {
    &-container {
      .feature {
        border-radius: 24px;
        margin-bottom: 16px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  .book_section {
    &-form {
      padding: 30px;
    }
  }

  .possibility_card {
    margin-bottom: 16px;
    flex: auto;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .styles {
    .style-block {
      margin-bottom: 16px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .information-card {
    margin-bottom: 16px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .demonstration_form {
    .fields {
      .fields_contacts {
        flex-direction: column;
        margin-top: 16px;
        margin-bottom: 16px;

        .input:first-child {
          margin-right: 0;
          margin-bottom: 16px;
        }
      }
    }
  }
}

@media only screen and (max-width: 375px) {
  header {
    .topbar .topbar-item .logo {
      width: 75px;
    }
  }

  .phone_card {
    .phone,
    .email {
      font-size: 24px;
    }
  }

  .button--orange {
    padding: 12px 10px;
    margin-right: 8px;
    margin-left: 8px;
  }

  .swiper-slide {
    .review-card {
      .review-info {
        .content {
          font-size: 14px;
        }
      }
    }
  }

  // CASES
  .cases.welcome_section {
    margin-bottom: 140px;

    .welcome_section-img {
      height: auto;
    }
  }

  .possibilities_section {
    &-container {
      .posibilities_image {
        .mockups-container {
          width: 75%;
          height: 320px;
        }
      }
    }
  }

  .branding_section {
    .branding {
      &-card {
        .branding-img {
          .branding-mock-container {
            width: 85%;
            height: 320px;
            transform: translateY(-30%);
            margin-bottom: -33%;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 350px) {
  .topbar {
    .button--orange {
      font-size: 12px;
    }
  }

  .team_section {
    &-container {
      .slogan {
        font-size: 24px;
      }
    }
  }
}
